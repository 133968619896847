import { render, staticRenderFns } from "./sportdetail.vue?vue&type=template&id=acf3fa46&scoped=true&"
import script from "./sportdetail.vue?vue&type=script&lang=js&"
export * from "./sportdetail.vue?vue&type=script&lang=js&"
import style0 from "./sportdetail.vue?vue&type=style&index=0&id=acf3fa46&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "acf3fa46",
  null
  
)

export default component.exports